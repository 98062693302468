
import { mapGetters } from 'vuex'
import { Currency } from '~/plugins/Currency'
import { formatter } from '~/plugins/date'
import ProductInformation from './ProductInformation.vue'
export default {
  components: { ProductInformation },
  props: {
    selectedPayment: {
      default: () => {},
    },
    product: {
      default: () => {},
    },
    promotion: {
      default: () => {},
    },
    getUserIP: {
      default: () => {},
    },
    type: {
      default: 0,
    },
  },
  computed: {
    ...mapGetters({
      getActiveUser: 'user/getActiveUser',
    }),
  },
  data() {
    return {
      loading: false,
      alertBox: { status: false, message: '', variant: '', type: '' },
      types: [
        { domain: 'Courses', type: 'course', id: 1 },
        { domain: 'Books', type: 'book', id: 2 },
        { domain: 'Events', type: 'event', id: 3 },
      ],
    }
  },
  methods: {
    Currency,
    formatter,
    SET_PRODUCT_PRICE() {
      if (this.promotion.id !== -1) {
        const fp = ((100 - this.product.discount) * this.product.price) / 100
        return ((100 - this.promotion.discount) * fp) / 100
      } else return ((100 - this.product.discount) * this.product.price) / 100
    },
    async SEND_TO_BUY_COURSE(paymentType, productType) {
      if (this.loading) return
      this.loading = true
      const response = await this.$api.post('Products/Pay', {
        productId: this.product.id,
        price: this.SET_PRODUCT_PRICE(),
        discount: this.product.discount,
        codeId: this.promotion.id !== -1 ? this.promotion.id : 0,
        paymentType,
        productType,
        currency: this.getUserIP.currency,
      })
      if (response.message === 'OK') {
        this.$store.commit(
          'productDetail/FIND_PRODUCT_THEN_SET_PURCHASE_TO_IT',
          this.product.id
        )
        setTimeout(() => {
          location.href = response.data
        }, 150)
      } else {
        this.alertBox = {
          status: true,
          variant: 'danger',
          message: this.$custom$t('valid.error'),
        }
      }
      this.loading = false
    },
    async SEND_FOR_BUY_EFT() {
      const type =
        this.productType !== 0
          ? { id: this.type }
          : this.types.find(
              (t) => t.domain === this.$route.params.productId.split('-')[0]
            )
      this.loading = true
      const response = await this.$api.post(`Products/Pay`, {
        productId: this.product.id,
        userId: this.getActiveUser.id,
        price: this.SET_PRODUCT_PRICE(),
        paymentType: 3,
        productType: type.id,
        discount: this.product.discount,
        currency: this.getUserIP.currency,
        codeId: this.promotion.id !== -1 ? this.promotion.id : 0,
      })
      if (response.message === 'OK') {
        this.alertBox = {
          status: true,
          message: this.$custom$t('valid.eftIsSucceed'),
          variant: 'success',
          type: 'eft',
        }
        const url =
          this.selectedPayment.productType === 1
            ? '/profilim/videolu-egitimlerim'
            : this.selectedPayment.productType === 2
            ? '/profilim/e-urunlerim'
            : '/profilim/etkinliklerim'
        this.$store.commit(
          'productDetail/FIND_PRODUCT_THEN_SET_PURCHASE_TO_IT',
          this.product.id
        )
        setTimeout(() => {
          document.getElementById('a-modal-body').scrollTop =
            document.getElementById('a-modal-body').scrollHeight
        }, 250)
        setTimeout(() => {
          this.$emit('close')
          location.href = this.localePath(url)
        }, 2500)
      } else if (
        response.message === 'REGISTERED' ||
        response.message === 'PRODUCT ALREADY EXISTS'
      ) {
        this.alertBox = {
          status: true,
          message: this.$custom$t('valid.registeredProduct'),
          variant: 'danger',
        }
      } else {
        this.alertBox = {
          status: true,
          message: this.$custom$t('valid.error'),
          variant: 'danger',
        }
      }
      this.loading = false
    },
    SET_PURCHASE_ACTION() {
      this.alertBox = { status: false, type: '' }
      if (
        this.selectedPayment.paymentType === 1 ||
        this.selectedPayment.paymentType === 2
      ) {
        this.SEND_TO_BUY_COURSE(
          this.selectedPayment.paymentType,
          this.selectedPayment.productType
        )
      } else if (this.selectedPayment.paymentType === 3) {
        this.SEND_FOR_BUY_EFT()
      }
    },
  },
}
