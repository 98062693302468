
import ProductPurchaseAction from './ProductPurchaseAction.vue'
export default {
  components: { ProductPurchaseAction },
  props: {
    noPurchase: {
      default: false,
      type: Boolean,
    },
    selectedPayment: {
      default: () => {},
    },
    product: {
      default: () => {},
    },
    promotion: {
      default: () => {},
    },
    getUserIP: {
      default: () => {},
    },
    getEFT: {
      default: () => [],
    },
    type: {
      default: 1,
    },
  },
  data() {
    return {
      selectedEft: null,
    }
  },
}
