
import ProductInformation from './ProductInformation.vue'
import { mapGetters } from 'vuex'
export default {
  components: { ProductInformation },
  props: {
    product: {
      default: () => {},
    },
    promotion: {
      default: () => ({ id: -1 }),
    },
    type: {
      default: 1,
    },
  },
  data() {
    return {
      alertBox: { status: false },
      loading: false,
    }
  },
  computed: {
    ...mapGetters({
      getUserIP: 'user/getUserIP',
    }),
  },
  methods: {
    async BUY_PRODUCT() {
      this.alertBox = { status: false }
      this.loading = true
      const response = await this.$api.post('Products/BuyFree', {
        productId: this.product.id,
        price: '0',
        discount: this.product.discount,
        codeId: this.promotion.id !== -1 ? this.promotion.id : 0,
        paymentType: 0,
        productType: this.type,
        currency: this.getUserIP.currency,
      })
      if (response.message === 'OK') {
        this.$store.commit(
          'productDetail/FIND_PRODUCT_THEN_SET_PURCHASE_TO_IT',
          this.product.id
        )
        this.alertBox = {
          status: true,
          variant: 'success',
          message: this.$custom$t('productHasbeenAddedToAccount'),
        }
        setTimeout(() => {
          location.href = response.data
        }, 250)
      } else {
        this.alertBox = {
          status: true,
          variant: 'danger',
          message: this.$custom$t('productHasNotbeenAddedToAccount'),
        }
      }
      this.loading = false
    },
  },
}
