export function convertM2H(n, offset = 0, time = true, gmt = false) {
  let transaction = '+'
  if (offset < 0) transaction = '-'
  const gmtReset = n - 180
  const offsetConvertToTime = offset / 60
  let num = time ? gmtReset + offsetConvertToTime : n
  if (num > 1439) num = num - 1440
  if (num < 0) num = -1 * num
  if (num < 0 && time) num = 1440 + num
  const hours = num / 60
  const rhours = Math.floor(hours)
  const minutes = (hours - rhours) * 60
  const rminutes = Math.round(minutes)
  return (
    (rhours < 10 ? `0${rhours}` : rhours) +
    ':' +
    (rminutes < 10 ? `0${rminutes}` : rminutes) +
    (time && gmt
      ? ` ${transaction}GMT` +
        convertM2H(offset !== 1 ? offset / 60 : 0, 0, false)
      : '')
  )
}
const opt = {
  year: 'numeric',
  month: 'long',
  day: 'numeric',
  hour: 'numeric',
  minute: 'numeric',
}
export const convertH2M = (timeInHour) => {
  const timeParts = timeInHour.split(':')
  return Number(timeParts[0]) * 60 + Number(timeParts[1])
}
export const formatter = (ms, options = opt) => {
  var locale = 'tr-TR'
  if (process.client)
    locale = window.navigator.userLanguage || window.navigator.language
  return new Date(ms).toLocaleDateString(locale, options)
}
