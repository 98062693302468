
import ProductPurchaseAction from './ProductPurchaseAction.vue'
export default {
  components: { ProductPurchaseAction },
  props: {
    selectedPayment: {
      default: () => {},
    },
    product: {
      default: () => {},
    },
    promotion: {
      default: () => {},
    },
    getUserIP: {
      default: () => {},
    },
    getEFT: {
      default: () => [],
    },
    type: {
      default: 1,
    },
  },
}
