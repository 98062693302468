
import Types from '@/static/database/Types.json'
import { mapGetters } from 'vuex'
export default {
  data() {
    return {
      loading: false,
    }
  },
  computed: {
    ...mapGetters({
      getActiveUser: 'user/getActiveUser',
    }),
    getTypeName() {
      return Types.find((t) => t.type === this.type).name
    },
  },
  props: {
    type: {
      default: 1,
    },
    alertBox: {
      default: () => ({ status: false }),
    },
  },
  methods: {
    SET_LOADING(loading = true) {
      this.loading = loading
    },
  },
}
