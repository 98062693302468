
import { mapGetters } from 'vuex'
import Modal from '../../Shared/Modal'
import { Currency } from '~/plugins/Currency'
import ProductPurchaseAction from './ProductPurchaseAction.vue'
import ProductPurchaseInformation from './ProductPurchaseInformation.vue'
import ProductPurchaseEFT from './ProductPurchaseEFT.vue'
import ProductPurchaseNoFound from './ProductPurchaseNoFound.vue'
import ProductPurchaseIyzico from './ProductPurchaseIyzico.vue'
import ProductPurchasePaypal from './ProductPurchasePaypal.vue'
import ProductHasBoughtAlready from './ProductHasBoughtAlready.vue'
import ProductGetOffer from './ProductGetOffer.vue'
import Types from '@/static/database/Types.json'
import ProductPurchaseFreeAction from './ProductPurchaseFreeAction.vue'
import CompanyIsSchool from './CompanyIsSchool.vue'

export default {
  components: {
    Modal,
    ProductPurchaseAction,
    ProductPurchaseInformation,
    ProductPurchaseEFT,
    ProductPurchaseNoFound,
    ProductPurchaseIyzico,
    ProductPurchasePaypal,
    ProductHasBoughtAlready,
    ProductGetOffer,
    ProductPurchaseFreeAction,
    CompanyIsSchool
  },
  data() {
    return {
      loading: false,
      promotionLoading: false,
      path: '',
      table: '',
      selectedEft: null,
      paymentList: [],
      alertBox: { status: false },
      code: '',
      promotion: { id: -1, status: '' },
      selectedPayment: { paymentType: '', productType: '', path: '' },
    }
  },
  props: {
    value: {
      default: false,
    },
    product: {
      default: () => {},
    },
    type: {
      default: 1,
    },
  },
  computed: {
    ...mapGetters({
      getUserIP: 'user/getUserIP',
      getSettings: 'getSettings',
    }),
    getIyzico() {
      return this.paymentList.find((p) => p.type === 1) || { id: -1 }
    },
    getPaypal() {
      return this.paymentList.find((p) => p.type === 2) || { id: -1 }
    },
    getEFT() {
      return this.paymentList.filter((p) => p.type === 3)
    },
    getPayoneer() {
      return this.paymentList.filter((p) => p.type === 4)
    },
    productIsFree() {
      return this.SET_PRODUCT_PRICE() === 0
    },
  },
  watch: {
    value(data) {
      if (data) {
        this.path = ''
        this.table = ''
        this.selectedEft = null
        this.code = ''
        this.selectedPayment = { paymentType: '', productType: '', path: '' }
        this.promotion = { id: -1, status: '' }
        this.GET_PAYMENT_LIST()
      }
    },
  },
  methods: {
    Currency,
    SET_PRODUCT_PRICE() {
      if (this.promotion.id !== -1) {
        const fp = ((100 - this.product.discount) * this.product.price) / 100
        return ((100 - this.promotion.discount) * fp) / 100
      } else return ((100 - this.product.discount) * this.product.price) / 100
    },
    async SEND_OFFER(loading) {
      loading()
      const response = await this.$api.post('Products/Pay', {
        productId: this.product.id,
        price: this.product.price,
        discount: this.product.discount,
        paymentType: 3,
        productType: this.type,
        currency: this.getUserIP.currency,
      })

      if (response.message === 'OK') {
        // location.href = this.localePath(
        //   `/profilim/${Types.find((t) => t.type === this.type)?.i18n}`
        // ) //response.data
      } else if (
        response.message === 'REGISTERED' ||
        response.message === 'PRODUCT ALREADY EXISTS'
      ) {
        this.alertBox = {
          status: true,
          message: this.$custom$t('valid.registeredProduct'),
          variant: 'danger',
        }
      } else {
        this.alertBox = {
          status: true,
          message: this.$custom$t('valid.error'),
          variant: 'danger',
        }
      }
      loading(false)
      setTimeout(() => {
        this.alertBox = {
          status: false,
        }
      }, 4000)
    },
    async SEND_TO_BUY_COURSE(paymentType, productType, path) {
      this.loading = true
      this.path = path
      const response = await this.$api.post('Products/Pay', {
        productId: this.product.id,
        price: this.product.price,
        discount: this.product.discount,
        paymentType,
        productType,
        currency: this.getUserIP.currency,
      })
      if (response.message === 'OK') {
        location.href = response.data
      } else {
        this.alertBox = {
          status: true,
          variant: 'danger',
          message: this.$custom$t('valid.error'),
        }
      }
    },
    async GET_PAYMENT_LIST() {
      this.loading = true
      const response = await this.$api.get('PaymentMethods')
      if (response.message === 'OK') this.paymentList = response.data
      else this.paymentList = []
      this.loading = false
    },
    SET_EFT_HAVALE(path) {
      this.path = path
      this.table = 'getEft'
    },
  },
  mounted() {
    if (this.value) {
      this.GET_PAYMENT_LIST()
    }
  },
}
