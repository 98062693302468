
import ProductPurchaseFreeAction from './ProductPurchaseFreeAction.vue'
import { Currency } from '~/plugins/Currency'

export default {
  components: { ProductPurchaseFreeAction },
  props: {
    product: {
      default: () => {},
    },
    promotion: {
      default: () => {},
    },
    getUserIP: {
      default: () => {},
    },
    getPayoneer: {
      default: () => [],
    },
    getEFT: {
      default: () => [],
    },
    getPaypal: {
      default: () => {},
    },
    getIyzico: {
      default: () => {},
    },
    type: {
      default: 1,
    },
  },
  data() {
    return {
      promotionLoading: false,
      code: '',
    }
  },
  computed: {
    productIsFree() {
      return this.SET_PRODUCT_PRICE() === 0
    },
  },
  methods: {
    Currency,
    SET_PRODUCT_PRICE() {
      if (this.promotion.id !== -1) {
        const fp = ((100 - this.product.discount) * this.product.price) / 100
        return ((100 - this.promotion.discount) * fp) / 100
      } else return ((100 - this.product.discount) * this.product.price) / 100
    },
    async GET_PROMOTION_CODE_VALUES() {
      this.promotionLoading = true
      this.$emit('change:promotion', { id: -1, status: '' })
      const response = await this.$api.get(
        `PromotionCodes/Apply?code=${this.code}&currency=${
          this.getUserIP.currency === 'TRY'
            ? 1
            : this.getUserIP.currency === 'USD'
            ? 2
            : 3
        }`
      )
      if (response.message === 'OK') {
        if (response.data !== null) {
          this.$emit('change:promotion', { ...response.data, status: 'ok' })
        } else
          this.promotion = this.$emit('change:promotion', {
            id: -1,
            status: 'invalidCode',
          })
      } else
        this.promotion = this.$emit('change:promotion', {
          id: -1,
          status: 'invalidCode',
        })

      this.promotionLoading = false
    },
  },
}
