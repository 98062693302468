
import { Currency } from '~/plugins/Currency'
import { convertM2H, formatter } from '~/plugins/date'
import { mapGetters } from 'vuex'
export default {
  props: {
    product: {
      default: () => {},
    },
    promotion: {
      default: () => ({ id: -1 }),
    },
  },
  methods: {
    Currency,
    convertM2H(time) {
      return convertM2H(time, this.getUserIP.offset, false)
    },
    formatter,
  },
  computed: {
    ...mapGetters({
      getUserIP: 'user/getUserIP',
    }),
  },
}
